/*.container {*/
/*  display: grid;*/

/*  grid-template-areas:*/
/*    "header"*/
/*    "content"*/
/*    "footer";*/

/*  grid-template-columns: 1fr;*/
/*  grid-template-rows: auto 1fr auto;*/
/*  !*grid-gap: 10px;*!*/

/*  background-color: salmon;*/

/*  min-height: 100vh;*/
/*}*/

/*header {*/
/*  grid-area: header;*/
/*  !*background-color: red;*!*/

/*  !*display: flex;*!*/
/*  !*justify-content: space-between;*!*/
/*  !*height: 50px;*!*/

/*  border-bottom-width: 2px;*/
/*  border-color: grey;*/
/*  border-bottom-width: 1px;*/
/*  border-bottom-style: solid;*/

/*}*/



/*.header-buttons > button {*/

/*  border: none;*/
/*  color: gray;*/
/*  padding: 20px;*/
/*  text-align: center;*/
/*  text-decoration: none;*/
/*  display: inline-block;*/
/*  font-size: 20px;*/
/*  margin: 4px 2px;*/
/*  cursor: pointer;*/
/*}*/

.big-button {
  background-color: transparent;
  border: none;
  color: gray;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

.big-button-with-border {
  background-color: transparent;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-style: solid;
  border-width: 4px;
  border-color: white;
}

/*.title {*/
/*  font-size: 3em;*/
/*  color: white;*/
/*  !*display: inline-flex;*!*/
/*  !*justify-content: space-between;*!*/
/*  !*align-items: center;*!*/
/*}*/

/*.header-buttons {*/
/*  !*order: 2;*!*/
/*}*/

/*.header-body {*/
/*  padding-top: 3px;*/
/*  padding-left: 20px;*/
/*}*/

/*main {*/
/*  grid-area: content;*/
/*  !*background-color: white;*!*/
/*  justify-self: center;*/
/*}*/

/*footer {*/
/*  grid-area: footer;*/
/*  !*background-color: purple;*!*/
/*}*/